<template>
  <div id="availability-search">
    <availability-search></availability-search>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
