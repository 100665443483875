import Vue from 'vue'
import App from './App.vue'
import AvailabilitySearch from "./components/AvailabilitySearch";

Vue.config.productionTip = false

Vue.component('availability-search', AvailabilitySearch)

new Vue({
  render: h => h(App),
}).$mount('#availability-search')
