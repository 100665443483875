<template>
  <div class="availability-search" :key="updateKey">
    <div>
      <date-picker placeholder="Start day" v-model="startDay" valueType="format"></date-picker>
    </div>
    <div>
      <select name="nights" id="nights" v-model="nights">
        <option :value="null" disabled selected>Nights</option>
        <option :value="3">3 Nights</option>
        <option :value="7">7 Nights</option>
        <option :value="10">10 Nights</option>
        <option :value="14">14 Nights</option>
        <option :value="21">21 Nights</option>
        <option :value="28">28 Nights</option>
      </select>
      <img width="40" :src="nightsIcon" alt="Nights Icon">
    </div>
    <div>
      <select name="guests" id="guests" v-model="guests">
        <option :value="null" disabled selected>Guests</option>
        <option :value="2">2 Guests</option>
        <option :value="3">3 Guests</option>
        <option :value="4">4 Guests</option>
        <option :value="5">5 Guests</option>
        <option :value="6">6 Guests</option>
        <option :value="7">7 Guests</option>
        <option :value="8">8 Guests</option>
        <option :value="9">9 Guests</option>
        <option :value="10">10 Guests</option>
      </select>
      <img width="40" :src="guestsIcon" alt="Guests Icon">
    </div>
    <div>
      <select name="pets" id="pets" v-model="pets">
        <option :value="null" disabled selected>Pets</option>
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
      <img width="40" :src="petsIcon" alt="Pets Icon">
    </div>
    <div>
      <select name="options" id="options" v-model="options">
        <option :value="null" disabled selected>Options</option>
      </select>
      <img width="40" :src="optionsIcon" alt="Options Icon">
    </div>
    <div @click.prevent="search">
      <div class="search-text">Search</div>
      <img src="30" :src="searchIcon" alt="Search icon">
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

window.moment = require('moment');

export default {
  name: "AvailabilitySearch",
  components: {DatePicker},
  data() {
    return {
      startDay: null,
      nights: null,
      guests: null,
      pets: null,
      options: null,

      updateKey: 1
    }
  },
  methods: {
    search() {
      if (!this.startDay) {
        alert('Please select a start day');
        return false;
      }

      if (!this.nights) {
        alert('Please select a number of nights');
        return false;
      }

      if (!this.guests) {
        alert('Please select a number of guests');
        return false;
      }

      let newUrl = '/our-cottages?start_day=' + this.startDay + '&nights=' + this.nights;

      if (this.guests) {
        newUrl = newUrl + '&guests=' + this.guests;
      }

      if (this.pets) {
        newUrl = newUrl + '&pets=' + this.pets;
      }

      window.location = newUrl;
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);

    let section;
    if (urlParams.has('start_day')) {
      // this.startDay = moment(urlParams.get('start_day'), 'YYYY-MM-DD').isValid() ? moment(urlParams.get('start_day'), 'YYYY-MM-DD') : null;
      this.startDay = moment(urlParams.get('start_day'), 'YYYY-MM-DD').isValid() ? urlParams.get('start_day') : null;
    }
    if (urlParams.has('nights')) {
      this.nights = Number(urlParams.get('nights'));
    }
    if (urlParams.has('guests')) {
      this.guests = Number(urlParams.get('guests'));
    }
    if (urlParams.has('pets')) {
      this.pets = Boolean(urlParams.get('pets'));
    }

    this.updateKey += 1;
  },
  computed: {
    imagePath() {
      return window.imagePath;
    },

    nightsIcon() {
      return this.imagePath + '/search/nights.png';
    },

    guestsIcon() {
      return this.imagePath + '/search/guests.png';
    },

    petsIcon() {
      return this.imagePath + '/search/pets.png';
    },

    optionsIcon() {
      return this.imagePath + '/search/options.png';
    },

    searchIcon() {
      return this.imagePath + '/search/search.png';
    }
  }
}
</script>

<style>
.availability-search {
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 5px;
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
  font-size: 17px;
  overflow: hidden;
}

.availability-search .mx-datepicker .mx-input, .availability-search .mx-datepicker .mx-input::placeholder {
  color: #000 !important;
}

.availability-search .mx-icon-calendar {
  color: #1F1F1F !important;
}

.availability-search > div {
  flex: 1;
  text-align: center;
  border-right: 1px solid #eee;
  padding: 11px 15px;

  align-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
}

.availability-search > div select {
  flex: 1;
}

.availability-search > div select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.availability-search > div select::-ms-expand {
  display: none;
}

.availability-search > div img {
  flex-shrink: 1;
  width: 22px !important;
}

/*.availability-search > div:hover {*/
/*background: #eee;*/
/*cursor: pointer;*/
/*}*/

.availability-search > div:last-child {
  border-right: none !important;
  background: rgb(2, 98, 106);
  color: #fff;
  align-self: stretch;
  display: flex;
}

.availability-search > div:last-child:hover {
  cursor: pointer;
}

.availability-search > div:last-child .search-text {
  flex: 1;
  text-align: left;

  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 5px;
}

.availability-search > div:last-child img {
  flex-shrink: 1;
}

.availability-search .mx-input {
  border: none !important;
  box-shadow: none !important;
  font-size: 17px;
}

.availability-search select {
  border: 0 !important;
}

@media screen and (max-width: 64.0625em) {
  .availability-search {
    flex-wrap: wrap !important;
  }

  .availability-search > div {
    flex-basis: 50% !important;
    border-bottom: 1px solid #eee;
    padding: 15px !important;
  }

  .availability-search > div:first-child {
    flex-basis: 100% !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    padding-right: 7px !important;
  }

  .availability-search > div:first-child .mx-datepicker {
    width: 100%;
  }

  .availability-search > div:first-child .mx-datepicker input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
